<template>
    <ul class="tab_sec">
        <li @click="delay.delay_method = 1" :class="{ active: delay.delay_method === 1 }">Delay</li>
        <li @click="delay.delay_method = 2" :class="{ active: delay.delay_method === 2 }">Datetime</li>
        <li @click="delay.delay_method = 3" :class="{ active: delay.delay_method === 3 }">Time</li>
    </ul>
    <div class="tab_content">
        <keep-alive v-show="delay.delay_method == 1">
            <div class="mt-2">
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Wait</label>
                        <div class="field_wpr">
                            <input type="number" min="0" v-model="delay.duration">
                        </div>
                    </div>
                    <div class="group_item">
                        <label class="input_label">&nbsp;</label>
                        <div class="field_wpr">
                            <multiselect v-model="delay.duration_unit" v-bind="days"></multiselect>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">What days of the week can we resume?</label>
                        <ul class="day_listing">
                            <li>
                                <label for="mon" class="checkbox">
                                    <input name="days[]" v-model="delay.mon" type="checkbox" :true-value="1" :false-value="0" id="mon" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                    <p>Mon</p>
                                </label>
                            </li>
                            <li>
                                <label for="tue" class="checkbox">
                                    <input name="days[]" v-model="delay.tue" type="checkbox" :true-value="1" :false-value="0" id="tue" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                    <p>Tue</p>
                                </label>
                            </li>
                            <li>
                                <label for="wed" class="checkbox">
                                    <input name="days[]" v-model="delay.wed" type="checkbox" :true-value="1" :false-value="0" id="wed" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                    <p>Wed</p>
                                </label>
                            </li>
                            <li>
                                <label for="thu" class="checkbox">
                                    <input name="days[]" v-model="delay.thu" type="checkbox" :true-value="1" :false-value="0" id="thu" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                    <p>Thu</p>
                                </label>
                            </li>
                            <li>
                                <label for="fri" class="checkbox">
                                    <input name="days[]" v-model="delay.fri" type="checkbox" :true-value="1" :false-value="0" id="fri" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                    <p>Fri</p>
                                </label>
                            </li>
                            <li>
                                <label for="sat" class="checkbox">
                                    <input name="days[]" v-model="delay.sat" type="checkbox" :true-value="1" :false-value="0" id="sat" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                    <p>Sat</p>
                                </label>
                            </li>
                            <li>
                                <label for="sun" class="checkbox">
                                    <input name="days[]" v-model="delay.sun" type="checkbox" :true-value="1" :false-value="0" id="sun" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                    <p>Sun</p>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="mt-2">
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">What time of day should we resume?</label>
                            <div class="group-col-3">
                                <div class="field_wpr">
                                    <multiselect v-model="delay.tod_hour" v-bind="hours"></multiselect>
                                </div>
                                <div class="field_wpr">
                                    <multiselect v-model="delay.tod_minute" v-bind="mins"></multiselect>
                                </div>
                                <div class="field_wpr">
                                    <multiselect v-model="delay.tod_ampm" v-bind="clock"></multiselect>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Timezone</label>
                            <div class="field_wpr">
                                <multiselect
                                  v-model="delay.time_zone"
                                  :options="timezones"
                                  valueProp="timezone"
                                  label="timezone"
                                  :searchable="true"
                                >
                                    <template v-slot:option="{ option }">
                                        <span>{{ `${ option.abbr} ${ option.utc_offset_str} ${ option.timezone}` }}</span>
                                    </template>
                                </multiselect>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </keep-alive>
        <keep-alive v-show="delay.delay_method == 2">
            <div class="mt-2">
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Date</label>
                        <div class="field_wpr">
                            <datepicker v-model="delay.sched_dt" :timezone="user.timezone ? user.timezone : 'UTC'" :min-date="moment().format('YYYY-MM-DD')" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">What time of day should we resume?</label>
                        <div class="group-col-3">
                            <div class="field_wpr">
                                <multiselect v-model="delay.sched_dt_hour" v-bind="hours"></multiselect>
                            </div>
                            <div class="field_wpr">
                                <multiselect v-model="delay.sched_dt_minute" v-bind="mins"></multiselect>
                            </div>
                            <div class="field_wpr">
                                <multiselect v-model="delay.sched_dt_ampm" v-bind="clock"></multiselect>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Timezone</label>
                        <div class="field_wpr">
                            <multiselect
                              v-model="delay.sched_dt_timezone"
                              :options="timezones"
                              valueProp="timezone"
                              label="timezone"
                              :searchable="true"
                            >
                                <template v-slot:option="{ option }">
                                    <span>{{ `${ option.abbr} ${ option.utc_offset_str} ${ option.timezone}` }}</span>
                                </template>
                            </multiselect>
                        </div>
                    </div>
                </div>
            </div>
        </keep-alive>
        <keep-alive v-show="delay.delay_method == 3">
            <div class="mt-2">
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">What time of day should we resume?</label>
                        <div class="group-col-3">
                            <div class="field_wpr">
                                <multiselect v-model="delay.sched_tod_hour" v-bind="hours"></multiselect>
                            </div>
                            <div class="field_wpr">
                                <multiselect v-model="delay.sched_tod_minute" v-bind="mins"></multiselect>
                            </div>
                            <div class="field_wpr">
                                <multiselect v-model="delay.sched_tod_ampm" v-bind="clock"></multiselect>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Timezone</label>
                        <div class="field_wpr">
                            <multiselect
                              v-model="delay.sched_time_timezone"
                              :options="timezones"
                              valueProp="timezone"
                              label="timezone"
                              :searchable="true"
                            >
                                <template v-slot:option="{ option }">
                                    <span>{{ `${ option.abbr} ${ option.utc_offset_str} ${ option.timezone}` }}</span>
                                </template>
                            </multiselect>
                        </div>
                    </div>
                </div>
            </div>
        </keep-alive>
    </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect'
    import axios from '@/services/axios'
    import moment from 'moment'

    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Schedule Tab',

        data () {
            return {
                delay: {
                    delay_method: 1,
                    duration: 1,
                    duration_unit: "days",
                    mon: 1,
                    tue: 1,
                    wed: 1,
                    thu: 1,
                    fri: 1,
                    sat: 1,
                    sun: 1,
                    tod_hour: "7",
                    tod_minute: "00",
                    tod_ampm: "am",
                    time_zone: 'Canada/Eastern',
                    sched_dt: new Date().toISOString(),
                    sched_dt_hour: "7",
                    sched_dt_minute: "00",
                    sched_dt_ampm: "am",
                    sched_dt_timezone: 'Canada/Eastern',
                    sched_tod_hour: "7",
                    sched_tod_minute: "00",
                    sched_tod_ampm: "am",
                    sched_time_timezone: 'Canada/Eastern',
                },
                days: {
                    mode: 'single',
                    value: ['Days'],
                    options: [
                        { value: 'days', label: 'Days' },
                        { value: 'hours', label: 'Hours' },
                        { value: 'minutes', label: 'Minutes' }
                    ],
                    createTag: true
                },
                mins: {
                    mode: 'single',
                    value: ['00'],
                    options: [
                        { value: '00', label: '00' },
                        { value: '01', label: '01' },
                        { value: '02', label: '02' },
                        { value: '03', label: '03' },
                        { value: '04', label: '04' },
                        { value: '05', label: '05' },
                        { value: '06', label: '06' },
                        { value: '07', label: '07' },
                        { value: '08', label: '08' },
                        { value: '09', label: '09' },
                        { value: '10', label: '10' },
                        { value: '11', label: '11' },
                        { value: '12', label: '12' },
                        { value: '13', label: '13' },
                        { value: '14', label: '14' },
                        { value: '15', label: '15' },
                        { value: '16', label: '16' },
                        { value: '17', label: '17' },
                        { value: '18', label: '18' },
                        { value: '19', label: '19' },
                        { value: '20', label: '20' },
                        { value: '21', label: '21' },
                        { value: '22', label: '22' },
                        { value: '23', label: '23' },
                        { value: '24', label: '24' },
                        { value: '25', label: '25' },
                        { value: '26', label: '26' },
                        { value: '27', label: '27' },
                        { value: '28', label: '28' },
                        { value: '29', label: '29' },
                        { value: '30', label: '30' },
                        { value: '31', label: '31' },
                        { value: '32', label: '32' },
                        { value: '33', label: '33' },
                        { value: '34', label: '34' },
                        { value: '35', label: '35' },
                        { value: '36', label: '36' },
                        { value: '37', label: '37' },
                        { value: '38', label: '38' },
                        { value: '39', label: '39' },
                        { value: '40', label: '40' },
                        { value: '41', label: '41' },
                        { value: '42', label: '42' },
                        { value: '43', label: '43' },
                        { value: '44', label: '44' },
                        { value: '45', label: '45' },
                        { value: '46', label: '46' },
                        { value: '47', label: '47' },
                        { value: '48', label: '48' },
                        { value: '49', label: '49' },
                        { value: '50', label: '50' },
                        { value: '51', label: '51' },
                        { value: '52', label: '52' },
                        { value: '53', label: '53' },
                        { value: '54', label: '54' },
                        { value: '55', label: '55' },
                        { value: '56', label: '56' },
                        { value: '57', label: '57' },
                        { value: '58', label: '58' },
                        { value: '59', label: '59' },
                    ],
                    createTag: true
                },
                hours: {
                    mode: 'single',
                    value: ['7'],
                    options: [
                        { value: '1', label: '1' },
                        { value: '2', label: '2' },
                        { value: '3', label: '3' },
                        { value: '4', label: '4' },
                        { value: '5', label: '5' },
                        { value: '6', label: '6' },
                        { value: '7', label: '7' },
                        { value: '8', label: '8' },
                        { value: '9', label: '9' },
                        { value: '10', label: '10' },
                        { value: '11', label: '11' },
                        { value: '12', label: '12' },
                    ],
                    createTag: true
                },
                clock: {
                    mode: 'single',
                    value: ['am'],
                    options: [
                        { value: 'am', label: 'AM' },
                        { value: 'pm', label: 'PM' }
                    ],
                    createTag: true
                },
                moment,
            };
        },

        props: {
            modelValue: Object,
        },

        emits: ['update:modelValue'],

        components: {
            Multiselect,
        },

        watch: {
            delay (delay) {
                const vm = this;

                vm.$emit('update:modelValue', delay);
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            timezones: state => state.commonModule.timezones,
        }),

        mounted () {
            const vm = this;

            vm.refreshDelay();

            if (vm.timezones.length == 0) {
                vm.getTimezones();
            }
        },

        methods: {
            ...mapActions({
                getTimezones: 'commonModule/getTimezones',
                storeDelay: 'commonModule/storeDelay',
            }),

            refreshDelay () {
                const vm = this;
                const timezone = vm.user.timezone || 'Canada/Eastern';

                vm.delay = {
                    delay_method: 1,
                    duration: 1,
                    duration_unit: "days",
                    mon: 1,
                    tue: 1,
                    wed: 1,
                    thu: 1,
                    fri: 1,
                    sat: 1,
                    sun: 1,
                    tod_hour: "7",
                    tod_minute: "00",
                    tod_ampm: "am",
                    time_zone: timezone,
                    sched_dt: new Date().toISOString(),
                    sched_dt_hour: "7",
                    sched_dt_minute: "00",
                    sched_dt_ampm: "am",
                    sched_dt_timezone: timezone,
                    sched_tod_hour: "7",
                    sched_tod_minute: "00",
                    sched_tod_ampm: "am",
                    sched_time_timezone: timezone
                };
            },

            scheduleDelay (action, formData, route = '/schedule/bulk-action') {
                const vm = this;

                const form = new FormData();
                form.append('action', action);

                for (let key  in formData) {
                    form.append(`formData[${key}]`, formData[key]);
                }

                vm.delay.sched_dt = moment(vm.delay.sched_dt).format('YYYY-MM-DD');

                for (let key in vm.delay) {
                    form.append(`delay[${key}]`, vm.delay[key]);
                }

                return axios.post(route, form, {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                });
            },
        },
    }
</script>
